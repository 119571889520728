<template>
  <v-row
    v-if="productType === productTypeEnum.BEGINNER"
    justify="center"
    class="justify-center h-full d-flex align-center"
  >
    <div class="px-16 py-8">
      <ProgressCircular />
      <h2>
        {{ $t('payment.title-beginner-package') }}
      </h2>
    </div>
  </v-row>
  <div
    v-else
    class="h-full pt-6 d-flex align-center flex-column white px-2"
  >
    <PaymentHeader :is-yearly="isYearly" />

    <Payment
      v-if="onboardingBillingData"
      ref="payment"
      :product-type="productType"
      :is-yearly="isYearly"
      :coupon-code="couponCode"
      :currency="currency"
      :customer-default="{
        ...onboardingBillingData,
        email: $auth.user.email,
        address: { ...onboardingBillingData.address, country: countryCode }
      }"
      @cart="cart = $event"
      @customer="customer = $event"
      @previewOrder="onPreviewOrderChange"
    />

    <v-row class="d-flex btns-wrapper mt-2 mb-6 w-full d-flex flex-row gap-1 gap-lg-3 justify-center">
      <v-btn
        outlined
        :color="$vuetify.theme.themes.light.markero.blue"
        class="py-6 px-md-16 flex-md-grow-0 flex-grow-1 back-btn"
        :disabled="isLoading"
        @click="goBack"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-btn
        elevation="20"
        :color="$vuetify.theme.themes.light.markero.blue"
        class="white--text py-6 px-md-16 flex-md-grow-0 flex-grow-1 proceed-btn"
        :loading="isLoading"
        @click="submit"
      >
        {{ $t('labels.proceed') }}
      </v-btn>
    </v-row>

    <v-row class="ma-0 ma-md-5">
      <v-col
        class="px-0"
        md="8"
        offset-md="2"
      >
        <PaymentProviderInfo />
      </v-col>
    </v-row>

    <v-row class="ma-5">
      <div class="flex-wrap justify-center d-flex">
        <IubendaDocumentLink
          type="terms"
          class="mx-1 mx-sm-2"
          @click.stop
        />
        <IubendaDocumentLink
          class="mx-1 mx-sm-2"
          type="privacyPolicy"
          @click.stop
        />
        <IubendaConsentLink class="mx-1 mx-sm-2" />
        <a
          :href="imprintUrl"
          target="_blank"
          class="mx-1 mx-sm-2"
          rel="noopener noreferrer"
        >{{ $t('navigation.labels.imprint') }}</a>
      </div>
    </v-row>
    <v-spacer class="h-full" />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import featureMixin from '@/mixins/feature'
import { ProductType } from '@/modules/productPackages/enums/ProductType'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'
import brandingMixin from '@/mixins/branding'
import Payment from './Payment.vue'
import PaymentProviderInfo from './PaymentProviderInfo.vue'

import CREATE_COMPANY_BILLING from '@/modules/auth/Register/queries/createCompanyBilling.gql'
import GET_ONBOARDING_BILLING_DATA from '@/modules/billwerk/subscribe/queries/getOnboardingBillingData.gql'
import HANDLE_ONBOARDING_BILLING_DATA from '@/modules/billwerk/subscribe/queries/handleOnboardingBillingData.gql'
import ASSIGN_ONBOARDING_BILLING_ORDER_ID from '@/modules/billwerk/subscribe/queries/assignOnboardingBillingOrderId.gql'
import { billwerkApi } from '../billwerk/lib'
import ProgressCircular from '@/components/ProgressCircular.vue'
import { Routes } from '@/components/product-finder/routes'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import PaymentHeader from './PaymentHeader.vue'
import { CurrencySymbol } from '@/modules/productPackages/enums/CurrencySymbol'

export default {
  components: { IubendaDocumentLink, IubendaConsentLink, Payment, PaymentProviderInfo, ProgressCircular, PaymentHeader },
  mixins: [featureMixin, brandingMixin],
  data () {
    this.productPackageQuery = JSON.parse(sessionStorage.getItem('productPackageQuery'))
    return {
      productTypeEnum: ProductType,
      productType: this.productPackageQuery?.productType,
      isYearly: this.productPackageQuery?.isYearly,
      countryCode: this.productPackageQuery?.countryCode,
      currencySymbol: this.productPackageQuery?.currencySymbol,
      couponCode: this.productPackageQuery?.couponCode || '',
      isLoading: false,
      cart: {},
      customer: {},
      onboardingBillingData: null,
      isBillingInProgress: false,
      isCouponValid: true
    }
  },
  computed: {
    currency () {
      const currencyMap = {
        [CurrencySymbol.SWISSFRANC]: 'CHF',
        [CurrencySymbol.EURO]: 'EUR'
      }

      return currencyMap[this.currencySymbol] || 'USD'
    },
    hasActivePackage () {
      return !!this.$auth?.user?.productType
    }
  },
  provide () {
    return {
      billingOnboardingDetailsData: {}
    }
  },
  watch: {
    isYearly () {
      this.setToSessionStorage()
    }
  },
  async mounted () {
    this.$tracking.event('Add Payment Info', 'Clicked')
    if (this.productType === ProductType.BEGINNER) {
      await this.createOrderWithoutPayment()
    }
  },
  methods: {
    onPreviewOrderChange (previewOrder) {
      this.isCouponValid = previewOrder.isCouponValid
    },
    async submit () {
      this.$tracking.event('Payment', 'Clicked', 'Proceed')
      if (!this.$refs.payment.validate()) {
        return
      }

      this.isLoading = true
      try {
        await this.saveBillingData()
        const order = await billwerkApi.createOrder({
          cart: this.isCouponValid ? this.cart : { ...this.cart, couponCode: '' },
          customer: this.customer
        })
        await this.saveOrderId(order.OrderId)
        const { Url } = await billwerkApi.paySignupInteractive({
          order,
          paymentMethod: this.customer.PaymentMethod,
          providerReturnUrl: new URL('/payment-finalize/signup', window.location.origin).toString()
        })
        if (Url) {
          window.location.href = Url
        } else {
          await this.createCompanyBilling()
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.payment.company-billing.error'))
      } finally {
        this.isLoading = false
      }
    },

    async createOrderWithoutPayment () {
      const { priceLists, packages } = this.$features.feature(this.featureNames.BILLWERK)?.config
      const paymentCycle = this.isYearly ? 'yearly' : 'monthly'
      const productPackage = packages.find((productPackage) =>
        this.productType === productPackage.productType &&
        (!productPackage.paymentCycle || productPackage.paymentCycle === paymentCycle)
      )

      const planVariantId = productPackage.billwerkId
      const priceListId = Object.values(priceLists).find(({ currency }) => currency === this.currency)?.id ?? priceLists.default.id

      try {
        const order = await billwerkApi.createOrder({
          cart: {
            priceListId,
            planVariantId
          },
          customer: {
            emailAddress: this.$auth.user.email,
            ...(this.countryCode && { address: { country: this.countryCode } })
          }
        })
        await this.saveOrderId(order.OrderId)
        await billwerkApi.paySignupInteractive({
          order,
          paymentMethod: 'None:None'
        })
        await this.createCompanyBilling()
      } catch (error) {
        Sentry.captureException(error)
      }
    },

    async saveOrderId (orderId) {
      await this.$apollo.mutate({
        mutation: ASSIGN_ONBOARDING_BILLING_ORDER_ID,
        variables: {
          input: { orderId }
        }
      })
    },

    async saveBillingData () {
      await this.$apollo.mutate({
        mutation: HANDLE_ONBOARDING_BILLING_DATA,
        variables: {
          input: {
            customer: {
              firstName: this.customer.firstName,
              lastName: this.customer.lastName,
              vatId: this.customer.vatId,
              companyName: this.customer.companyName,
              address: {
                street: this.customer.address.street,
                houseNumber: this.customer.address.houseNumber,
                zip: this.customer.address.postalCode,
                city: this.customer.address.city,
                countryCode: this.customer.address.country.toLowerCase()
              }
            }
          }
        }
      })
    },

    async createCompanyBilling () {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_COMPANY_BILLING,
          variables: {
            input: {
              companyName: '',
              productType: this.productType,
              components: [],
              isYearly: this.isYearly
            }
          }
        })
        this.$tracking.event('Account Creation', 'Loaded', 'Initial App Load')
        sessionStorage.removeItem('productPackageQuery')
        return this.$router.push({ name: 'signup-markero', params: { step: Routes.WEBSITE } })
      } catch (error) {
        showSnackbarMessage('error', this.$t('alerts.payment.company-billing.error'))
        this.$router.push({ name: 'product-packages' })
      }
    },

    setToSessionStorage () {
      sessionStorage.setItem('productPackageQuery', JSON.stringify(
        {
          productType: this.productType,
          isYearly: this.isYearly,
          countryCode: this.countryCode,
          currencySymbol: this.currencySymbol,
          couponCode: this.couponCode
        }
      ))
    },

    goBack () {
      this.$tracking.event('Payment', 'Clicked', 'Back')
      this.$router.push({
        name: 'product-packages',
        query: {
          countryCode: this.$route.query?.countryCode,
          readableId: this.$route.query?.readableId,
          currencySymbol: this.$route.query?.currencySymbol,
          isYearly: this.$route.query?.isYearly
        }
      })
    }
  },
  apollo: {
    onboardingBillingData: { query: GET_ONBOARDING_BILLING_DATA, update: (data) => data.onboardingBillingData.customer }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .benefits {
    justify-content: space-evenly;
  }
}

.nowrap {
  white-space: nowrap;
}
.inline-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 115px;
}

.part-of {
  font-size: 14px;
  color: rgb(128, 128, 128);
  align-self: center;
  margin: 0 5px;
}

::v-deep fieldset   {
  background-color: white;
}

.shadow {
  box-shadow: 20px 20px 180px 20px rgba(48, 34, 147, 0.10) !important;
}
</style>

<style>
.card-shadow-invert {
  box-shadow: 20px 20px 180px 20px rgba(48, 34, 147, 0.10) !important;
}
.v-input--radio-group__input {
  gap: 1rem;
}
@media (max-width: 600px) {
  .proceed-btn, .back-btn {
    min-width: 120px !important;
  }
}
</style>
